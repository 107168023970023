<template>
  <AppNumberField label="Quantidade" :precision="precision" />
</template>

<script setup>

const prop = defineProps({
  product: {
    type: Object,
  },
})

const precision = computed(() => {
  return prop.product?.allow_fractional_sales ? 3 : 0
})
</script>

<style lang="scss" scoped>

</style>